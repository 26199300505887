@import '@/styles/_variable.scss';
@import '@/styles/./mixin.scss';

.header {
    &:global(.landing-header) {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;

        .navBtn {
            display: none;
        }
    }

    .stickybar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        padding: 20px;
        left: 0;
        top: 0;
        z-index: 10;

        .logo {
            position: relative;
            z-index: 2;
        }

        >img {
            position: relative;
            z-index: 2;
        }
    }

    .navWrap {
        position: fixed;
        padding: 20px;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        left: 0;
        top: 0;
        display: none;
        z-index: 2;

        @include pad-l {
            max-width: var(--page-max-size);
            left: 0;
            right: 0;
            margin-inline: auto;
        }

        &.open {
            display: block;
            animation: h-ani-1 0.4s ease-in-out;

            >div {
                transform: scale(0);
                animation: h-ani-2 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
                animation-fill-mode: both;
                animation-delay: 0.4s;
                transform-origin: 0 0;
            }

            .closeBtn {
                opacity: 0;
                animation: h-ani-1 0.4s ease-in-out;
                animation-fill-mode: both;
                animation-delay: 0.9s;
            }

            &.menu {
                div {
                    transform-origin: 100% 0%;
                }

                figure {
                    opacity: 0;
                    transform: translateX(5%);
                    animation: h-ani-4 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
                    animation-fill-mode: both;
                    animation-delay: 0.9s;
                }

                a {
                    opacity: 0;
                    transform: translateX(5%);
                    animation: h-ani-5 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
                    animation-fill-mode: both;
                    animation-delay: 1.2s;

                    &:nth-child(2) {
                        animation-delay: 1.4s;
                    }

                    &:nth-child(3) {
                        animation-delay: 1.6s;
                    }

                    &:nth-child(4) {
                        animation-delay: 1.8s;
                    }

                    &:nth-child(5) {
                        animation-delay: 2.0s;
                    }
                }
            }

            .langNav {
                button {
                    opacity: 0;
                    transform: translateX(5%);
                    animation: h-ani-3 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
                    animation-fill-mode: both;
                    animation-delay: 1.1s;

                    &:nth-child(2) {
                        animation-delay: 1.3s;
                    }

                    &:nth-child(3) {
                        animation-delay: 1.5s;
                    }

                    &:nth-child(4) {
                        animation-delay: 1.7s;
                    }

                    &:nth-child(5) {
                        animation-delay: 1.9s;
                    }
                }
            }
        }

        .closeBtn,
        i {
            border: none;
            background: none;
            width: 17px;
            height: 17px;
            right: 12px;
            top: 12px;
            position: absolute;

            svg {
                width: inherit;
                height: inherit;
            }
        }

        i {
            right: auto;
            left: 12px;
        }

        >div {
            border-radius: var(--size-12);
            background-color: var(--color-white);
            padding-block-start: 44px;
            padding-block-end: 18px;
            padding-inline: 12px;
            box-shadow: var(--box-shadow);
            position: relative;
        }
    }

    .menu {
        >div {
            display: flex;
            flex-direction: column;
            row-gap: var(--size-20);

            figure {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            nav {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                --target-color: var(--color-yellow);
                gap: 1.0769em;

                a {
                    box-shadow: var(--box-shadow);
                    background-color: var(--target-color);
                    color: var(--color-white);
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    font-size: 2em;
                    line-height: 1.1;
                    font-weight: 300;
                    border-radius: 0.461em;
                    padding-inline: 0.384em;
                    padding-block-start: 0.1923em;
                    padding-block-end: 0.31em;

                    span {
                        flex-grow: 1;
                    }

                    svg {
                        --circle-color: var(--color-white);
                        --arrow-color: var(--target-color);
                        block-size: 1.1538em;
                        inline-size: 1.1538em;
                    }
                }
            }
        }
    }

    .lang {
        .langNav {
            button {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                font-size: 3.07em;
                line-height: 1.425;
                font-weight: 300;
                color: inherit;
                padding: 0 0.1em 0.2em 0.1em;
                border: none;
                background: none;
                border-top: 1px solid #F3D682;

                &:nth-child(2) {
                    border-top-color: var(--color-orange);
                }

                &:nth-child(3) {
                    border-top-color: var(--color-green);
                }

                svg {
                    width: 1em;
                    height: 1em;
                    --circle-color: var(--color-grayish-orange);
                    --arrow-color: var(--color-dark-gray);
                }
            }
        }
    }

    .langBtn,
    .navBtn,
    .backBtn {
        width: 42px;
        height: 42px;
        cursor: pointer;
        background-color: var(--color-white);
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        border: none;
        padding: 0;
    }

    .langBtn {
        &.open {
            z-index: 3;
            pointer-events: none;
        }
    }

    .navBtn {
        row-gap: 4px;

        i {
            width: 19px;
            min-height: 1px;
            background-color: $color-dark;

            &:nth-child(1) {
                animation: outT 0.8s backwards;
                animation-direction: reverse;
            }

            &:nth-child(2) {
                animation: outM 0.8s backwards;
                animation-direction: reverse;
            }

            &:nth-child(3) {
                animation: outBtm 0.8s backwards;
                animation-direction: reverse;
            }
        }

        &.open {
            z-index: 3;

            i:nth-child(1) {
                animation: inT 0.8s forwards;
            }

            i:nth-child(2) {
                animation: inM 0.8s forwards;
            }

            i:nth-child(3) {
                animation: inBtm 0.8s forwards;
            }
        }
    }

    .langBtn {
        svg {
            width: 18px;
            height: 18px;
        }
    }

    .backBtn {
        svg {
            width: 20px;
            height: 8px;
        }
    }
}

@keyframes inT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(5px) rotate(0deg);
    }

    100% {
        transform: translateY(5px) rotate(135deg);
    }
}

@keyframes inBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(-5px) rotate(0deg);
    }

    100% {
        transform: translateY(-5px) rotate(135deg);
    }
}

@keyframes inM {
    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(45deg);
    }
}

@keyframes outT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(5px) rotate(0deg);
    }

    100% {
        transform: translateY(5px) rotate(135deg);
    }
}

@keyframes outM {
    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(45deg);
    }
}

@keyframes outBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    50% {
        transform: translateY(-5px) rotate(0deg);
    }

    100% {
        transform: translateY(-5px) rotate(135deg);
    }
}

@keyframes h-ani-1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes h-ani-2 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes h-ani-3 {
    0% {
        transform: translateX(5%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes h-ani-4 {
    0% {
        transform: translateY(5%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes h-ani-5 {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}