// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@400;900&display=swap');
@import url('https://fonts.font.im/css2?family=Noto+Serif+SC:wght@400;900&display=swap');


@font-face {
    font-family: 'notosanssc';
    src: url('/fonts/Noto Sans SC Regular.woff2') format('woff2'),
         url('/fonts/Noto Sans SC Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'notosanssc';
    src: url('/fonts/Noto Sans SC Light.woff2') format('woff2'),
         url('/fonts/Noto Sans SC Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSlab';
    src: url('/fonts/Josefin Slab SemiBold.woff2') format('woff2'),
         url('/fonts/Josefin Slab SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir LT Std 35 Light.woff2') format('woff2'),
         url('/fonts/Avenir LT Std 35 Light.woff') format('woff');
    font-weight: 350;
    font-style: normal;
}
@font-face {
    font-family: 'parisienne';
    src: url('/fonts/Parisienne-Regular.woff2') format('woff2'),
         url('/fonts/Parisienne-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}