/* Hide the Cookiebot logo */
a#CybotCookiebotDialogPoweredbyCybot, 
div#CybotCookiebotDialogPoweredByText { 
    display: none; 
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none !important;
}

/* Hide the default privacy policy statement that appears at the bottom of the page when a user first visits the site */
.CookieDeclaration {
    display: none;
}

/* Hide the "Cookie declaration last updated on 2/5/24 by Cookiebot" at the bottom of the detail tabs  */
#CybotCookiebotDialogDetailFooter{
    display: none;
}