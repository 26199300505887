@import '@/styles/_variable.scss';

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--color-white);
    color: var(--color-black);
    border-top-left-radius: var(--size-12);
    border-top-right-radius: var(--size-12);

    &.pageReady {
        animation: a-pl-1 1s cubic-bezier(0.68, -0.12, 0, 1.15);
        animation-delay: 1s;
        animation-fill-mode: both;

        .content {
            figure {
                opacity: 0;
                animation: a-pl-2 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
                animation-delay: 1.2s;
                animation-fill-mode: both;

                &:nth-child(2) {
                    animation-delay: 1.4s;
                }
            }

            p {
                opacity: 0;
                animation: a-pl-2 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
                animation-delay: 1.4s;
                animation-fill-mode: both;
            }
    
            .eie {
                opacity: 0;
                animation: a-pl-2 0.5s cubic-bezier(0.68, -0.12, 0, 1.15);
                animation-delay: 1.6s;
                animation-fill-mode: both;
            }
        }
    }

    .content {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 2.23em 1.538em 0.76em 0.923em;
        gap: 1.4em;
        block-size: 5.769em;

        figure {
            display: inline-flex;
            align-items: center;

            img {
                width: auto;
            }

            &:nth-child(1) {
                img {
                    height: 2.769em;
                }
            }

            &:nth-child(2) {
                img {
                    height: 1.923em;
                }
            }
        }

        .eie {
            min-width: 3.846em;
            position: relative;

            img {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                display: block;
                width: 100%;
            }
        }

        p {
            flex-grow: 1;
            font-family: var(--font-avenir);
            font-weight: 350;
            font-size: 0.84em;
            line-height: 1.8;
            text-align: right;
            margin-inline-end: -0.8em;
        }
    }
}

@keyframes a-pl-1 {
    0% {
        transform: translateY(150%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes a-pl-2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}