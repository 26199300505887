:root {
  --color-white: #FFFFFF;
  --color-yellow: #F3D682;
  --color-orange: #E2AD7A;
  --color-green: #90B59F;
  --color-black: #000000;
  --color-grayish-orange: #F6F1EA;
  --color-dark-gray: #0B0804;
  
  --size-5: 0.384em;
  --size-12: 0.9230em;
  --size-16: 1.230769em;
  --size-18: 1.384em;
  --size-20: 1.5384em;
  --size-24: 1.84615em;


  --page-max-size: 600px;

  --font-notosanssc: 'notosanssc', sans-serif;
  --font-josefinslab: 'JosefinSlab', sans-serif;
  --font-avenir: 'Avenir', sans-serif;
  --font-parisienne: 'parisienne', sans-serif;

  --box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.08) ;
}