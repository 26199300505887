@import './variable.scss';
@import './fonts.scss';
@import './mixin.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html {
  background-color: var(--color-grayish-orange);
  min-height: 100%;

  body {
    // user-select: none;
    font-size: 13px;
    font-size: 3.334vw;
    background-color: var(--color-grayish-orange);
    font-family: var(--font-notosanssc);
    color: var(--color-dark-gray);
    line-height: 1.84;
    font-weight: 400;

    // @include phone-s {    
    //   font-size: 12.5px; 
    // }
    @include pad-l {
      font-size: 18px;
    }
  }
}

input,
button,
label {
  font-family: inherit;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

svg {
  --circle-color: var(--color-white);
  --arrow-color: var(--target-color);
}

a {
  color: inherit;
  text-decoration: underline;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  @include pad-l {
    max-width: var(--page-max-size);
    margin: 0 auto;
  }

  >* {
    width: 100%;
  }

  main {
    flex-grow: 1;
    display: flex;

    >div {
      width: 100%;
    }
  }
}

.container {
  padding: 0px 25px;
}

// .maxContainer {
//   max-width: 78em;
//   margin-inline: auto;
// }


.swiper-pagination {
  position: static;
  padding-top: 15px;

  .swiper-pagination-bullet {
    background-color: var(--target-color);
    opacity: 0.3;
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet-active {
    --swiper-theme-color: var(--target-color);
    opacity: 1;
  }
}

.pageLoader {
  width: 80px !important;
  height: 80px !important;
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  margin: auto;

  img {
    animation: loading 1s steps(4, end) infinite;
    display: block;
    width: 100%;
  }
}

@keyframes loading {
  to {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-320px);
  }
}

@keyframes ani-y-100-0 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes ani-y-0-100 {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes moveFromTop-100 {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.backBtn {
  width: 42px;
  height: 42px;
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border: none;
  padding: 0;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 11;
  .menu-open & {
    z-index: 9;
  }

  svg {
    width: 1em;
    height: 1em;
    --circle-color: var(--color-grayish-orange);
    --arrow-color: var(--color-dark-gray);
  }
}